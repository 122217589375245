<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <footer class="bg-secondary text-white py-16 relative">
    <div class="container mx-auto px-5">
      <div class="mb-8">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/4 mb-8 md:mb-0">
            <h2 class="text-xl font-bold text-primary mb-4">Programs</h2>
            <ul class="list-none">
              <li><a href="/packages/shooting" class="py-1 block hover:text-primary transition-colors duration-100 ">Shot Analysis</a></li>
              <li><a href="/packages/sessions" class="py-1 block hover:text-primary transition-colors duration-100 ">Book Your Session</a></li>
            </ul>
          </div>
          <div class="w-full md:w-1/4 mb-8 md:mb-0">
            <h2 class="text-xl font-bold text-primary mb-4">Quick Links</h2>
            <ul class="list-none">
              <li><a href="/#about" class="py-1 block hover:text-primary transition-colors duration-100">About</a></li>
              <li><a href="/#testimonials" class="py-1 block hover:text-primary transition-colors duration-100">Testimonials</a></li>
            </ul>
          </div>
          <div class="w-full md:w-1/4 mb-8 md:mb-0">
            <h2 class="text-xl font-bold text-primary mb-4">Contact Us</h2>
            <ul class="list-none">
              <li><a href="mailto:info@repsandrange.com" class="py-1 block hover:text-primary transition-colors duration-100">rawadeid@repsandrange.com</a></li>
              <li><a href="#" class="py-1 block hover:text-primary transition-colors duration-100">Beirut, Lebanon</a></li>
            </ul>
          </div>
          <div class="w-full md:w-1/4 mb-8 md:mb-0">
            <h2 class="text-xl font-bold text-primary mb-4">Connect with us</h2>
            <div class="flex space-x-4">
              <a href="https://www.instagram.com/repsandrange?igsh=NXVqZG9sOThqZzNi" target="_blank" rel="noopener noreferrer" class="text-white hover:text-primary transition-colors duration-100">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                </svg>
              </a>
              <a href="https://www.tiktok.com/@rawadeid?_t=ZS-8slD2fsUsrA&_r=1" target="_blank" rel="noopener noreferrer" class="text-white hover:text-primary transition-colors duration-100">
                <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

  
  <script lang="ts">
  import { defineComponent, computed } from 'vue'
  
  export default defineComponent({
    setup() {
      const currentYear = computed(() => new Date().getFullYear())
      
      return {
        currentYear
      }
    }
  })
  </script>
  
  <style scoped>
  /* Add any custom styles here, or rely on Tailwind classes */
  </style>
  