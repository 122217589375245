<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="px-6 py-3 bg-secondary text-white lg:px-24 shadow-md">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <router-link to="/">
          <img src="@/assets/logo-dark.png" alt="Logo" class="logo-dimensions" />
        </router-link>      
      </div>
      <div class="lg:hidden">
        <button @click="toggleMenu" class="text-white focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-10 lg:text-md">
        <div
          v-for="link in links"
          :key="link.text"
          class="relative px-2 py-1 rounded transition-colors duration-100 flex-grow hover:bg-primary group"
          @mouseenter="showDropdown(link.text)"
          @mouseleave="hideDropdown"
        >
          <a :href="link.url" class="block w-full h-full group-hover:text-white">
            {{ link.text }}
          </a>

          <!-- Dropdown menu for Packages -->
          <div
            v-if="link.text === 'Packages'"
            class="absolute left-0 top-full pt-3 w-48 bg-secondary text-white rounded-md shadow-lg z-10 transition-all duration-300 ease-in-out origin-top"
            :class="{ 'opacity-0 invisible scale-95': !isDropdownOpen, 'opacity-100 visible scale-100': isDropdownOpen }"
            @mouseenter="keepDropdownOpen"
            @mouseleave="hideDropdown"
          >
            <a
              v-for="submenu in programsSubmenu"
              :key="submenu.text"
              :href="submenu.url"
              class="block px-4 py-2 text-sm hover:bg-primary hover:text-white transition-colors duration-100 first:rounded-t-md last:rounded-b-md"
            >
              {{ submenu.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="transition-all duration-300 ease-out"
      enter-from-class="opacity-0 max-h-0"
      enter-to-class="opacity-100 max-h-[400px]"
      leave-active-class="transition-all duration-300 ease-in"
      leave-from-class="opacity-100 max-h-[400px]"
      leave-to-class="opacity-0 max-h-0"
    >
      <div v-if="isMenuOpen" class="mt-4 lg:hidden overflow-hidden">
        <div
          v-for="link in links"
          :key="link.text"
          class="block py-2 text-left rounded transition-colors duration-100"
        >
          <a 
            v-if="link.text !== 'Packages'" 
            :href="link.url" 
            class="block px-2 py-2 hover:bg-primary hover:text-white transition-colors duration-100 rounded"
          >
            {{ link.text }}
          </a>
          <div v-else class="relative">
            <button 
              @click="toggleProgramsSubmenu" 
              class="w-full px-2 py-2 text-left hover:bg-primary hover:text-white transition-colors duration-100 rounded flex justify-between items-center"
            >
              {{ link.text }}
              <svg 
                class="w-4 h-4 transition-transform duration-200" 
                :class="{ 'rotate-180': isProgramsSubmenuOpen }"
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            <transition
              enter-active-class="transition-all duration-300 ease-out"
              enter-from-class="opacity-0 max-h-0"
              enter-to-class="opacity-100 max-h-[400px]"
              leave-active-class="transition-all duration-300 ease-in"
              leave-from-class="opacity-100 max-h-[400px]"
              leave-to-class="opacity-0 max-h-0"
            >
              <div v-if="isProgramsSubmenuOpen" class="overflow-hidden bg-gray-700 rounded mt-1">
                <a
                  v-for="submenu in programsSubmenu"
                  :key="submenu.text"
                  :href="submenu.url"
                  class="block px-4 py-2 text-sm hover:bg-primary hover:text-white transition-colors duration-100"
                >
                  {{ submenu.text }}
                </a>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const links = ref([
      { text: 'About', url: '/#about' },
      { text: 'Testimonials', url: '/#testimonials' },
      { text: 'Packages', url: '/#packages' },
      { text: 'Merch', url: '/merch' },
    ])

    const programsSubmenu = ref([
      { text: 'Shot Analysis', url: '/packages/shooting' },
      { text: 'Book Your Session', url: '/packages/sessions' },
    ])
    const isProgramsSubmenuOpen = ref(false)

    const toggleProgramsSubmenu = () => {
      isProgramsSubmenuOpen.value = !isProgramsSubmenuOpen.value
    }
    const isMenuOpen = ref(false)
    const isDropdownOpen = ref(false)

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
      if (!isMenuOpen.value) {
        isProgramsSubmenuOpen.value = false
      }
    }

    const showDropdown = (linkText: string) => {
      if (linkText === 'Packages') {
        isDropdownOpen.value = true
      }
    }

    const hideDropdown = () => {
      setTimeout(() => {
        isDropdownOpen.value = false
      }, 150)
    }

    const keepDropdownOpen = () => {
      isDropdownOpen.value = true
    }

    return { 
      links, 
      programsSubmenu, 
      isMenuOpen, 
      isDropdownOpen, 
      toggleMenu, 
      showDropdown, 
      hideDropdown, 
      toggleProgramsSubmenu, 
      isProgramsSubmenuOpen, 
      keepDropdownOpen 
    }
  }
})
</script>

<style scoped>
.logo-dimensions {
  width: 180px;
}
</style>