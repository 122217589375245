import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/logo-dark.png'


const _hoisted_1 = { class: "px-6 py-3 bg-secondary text-white lg:px-24 shadow-md" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "lg:hidden" }
const _hoisted_5 = { class: "hidden lg:flex lg:gap-10 lg:text-md" }
const _hoisted_6 = ["onMouseenter"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 0,
  class: "mt-4 lg:hidden overflow-hidden"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 1,
  class: "relative"
}
const _hoisted_12 = {
  key: 0,
  class: "overflow-hidden bg-gray-700 rounded mt-1"
}
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Logo",
              class: "logo-dimensions"
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
          class: "text-white focus:outline-none"
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("svg", {
            class: "w-6 h-6",
            fill: "none",
            stroke: "currentColor",
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d: "M4 6h16M4 12h16m-7 6h7"
            })
          ], -1)
        ]))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
          return (_openBlock(), _createElementBlock("div", {
            key: link.text,
            class: "relative px-2 py-1 rounded transition-colors duration-100 flex-grow hover:bg-primary group",
            onMouseenter: ($event: any) => (_ctx.showDropdown(link.text)),
            onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hideDropdown && _ctx.hideDropdown(...args)))
          }, [
            _createElementVNode("a", {
              href: link.url,
              class: "block w-full h-full group-hover:text-white"
            }, _toDisplayString(link.text), 9, _hoisted_7),
            (link.text === 'Packages')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["absolute left-0 top-full pt-3 w-48 bg-secondary text-white rounded-md shadow-lg z-10 transition-all duration-300 ease-in-out origin-top", { 'opacity-0 invisible scale-95': !_ctx.isDropdownOpen, 'opacity-100 visible scale-100': _ctx.isDropdownOpen }]),
                  onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keepDropdownOpen && _ctx.keepDropdownOpen(...args))),
                  onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hideDropdown && _ctx.hideDropdown(...args)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programsSubmenu, (submenu) => {
                    return (_openBlock(), _createElementBlock("a", {
                      key: submenu.text,
                      href: submenu.url,
                      class: "block px-4 py-2 text-sm hover:bg-primary hover:text-white transition-colors duration-100 first:rounded-t-md last:rounded-b-md"
                    }, _toDisplayString(submenu.text), 9, _hoisted_8))
                  }), 128))
                ], 34))
              : _createCommentVNode("", true)
          ], 40, _hoisted_6))
        }), 128))
      ])
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "transition-all duration-300 ease-out",
      "enter-from-class": "opacity-0 max-h-0",
      "enter-to-class": "opacity-100 max-h-[400px]",
      "leave-active-class": "transition-all duration-300 ease-in",
      "leave-from-class": "opacity-100 max-h-[400px]",
      "leave-to-class": "opacity-0 max-h-0"
    }, {
      default: _withCtx(() => [
        (_ctx.isMenuOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: link.text,
                  class: "block py-2 text-left rounded transition-colors duration-100"
                }, [
                  (link.text !== 'Packages')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: link.url,
                        class: "block px-2 py-2 hover:bg-primary hover:text-white transition-colors duration-100 rounded"
                      }, _toDisplayString(link.text), 9, _hoisted_10))
                    : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("button", {
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleProgramsSubmenu && _ctx.toggleProgramsSubmenu(...args))),
                          class: "w-full px-2 py-2 text-left hover:bg-primary hover:text-white transition-colors duration-100 rounded flex justify-between items-center"
                        }, [
                          _createTextVNode(_toDisplayString(link.text) + " ", 1),
                          (_openBlock(), _createElementBlock("svg", {
                            class: _normalizeClass(["w-4 h-4 transition-transform duration-200", { 'rotate-180': _ctx.isProgramsSubmenuOpen }]),
                            fill: "none",
                            stroke: "currentColor",
                            viewBox: "0 0 24 24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, _cache[7] || (_cache[7] = [
                            _createElementVNode("path", {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M19 9l-7 7-7-7"
                            }, null, -1)
                          ]), 2))
                        ]),
                        _createVNode(_Transition, {
                          "enter-active-class": "transition-all duration-300 ease-out",
                          "enter-from-class": "opacity-0 max-h-0",
                          "enter-to-class": "opacity-100 max-h-[400px]",
                          "leave-active-class": "transition-all duration-300 ease-in",
                          "leave-from-class": "opacity-100 max-h-[400px]",
                          "leave-to-class": "opacity-0 max-h-0"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isProgramsSubmenuOpen)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programsSubmenu, (submenu) => {
                                    return (_openBlock(), _createElementBlock("a", {
                                      key: submenu.text,
                                      href: submenu.url,
                                      class: "block px-4 py-2 text-sm hover:bg-primary hover:text-white transition-colors duration-100"
                                    }, _toDisplayString(submenu.text), 9, _hoisted_13))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}