import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { 
    path: "/", 
    name: "home", 
    component: () => import("@/presentation/Landing/Home/Home.vue"), 
    meta: { requiresAuth: false }
  },
  {
    component: () => import("@/presentation/Landing/Programs/Shooting/Shooting.vue"),
    name: 'shooting',
    path: '/packages/shooting',
    meta: { requiresAuth: false }

  },
  {
    component: () => import("@/presentation/Landing/Programs/Conditioning/Conditioning.vue"),
    name: 'strength',
    path: '/packages/sessions',
    meta: { requiresAuth: false }

  },
  {
    component: () => import("@/presentation/Landing/Merch/Merch.vue"),
    name: 'landing-merch',
    path: '/merch',
    meta: { requiresAuth: false }

  },


]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Return to previous scroll position
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  }
})


export default router
